/**
 * List Schema of DappDailyCache
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {DappDailyCache} from '@/model/resource/DappDailyCache'

/* TODO: review generated schema */
export class ListDappDailyCacheSchema extends DefaultSchema {
  readonly name = 'ListDappDailyCache'

  readonly fieldSet: FieldSet<DappDailyCache> = {
    score: (): FieldComponent => ({
      is: Component.Render,
    }),
    transactionCountToday: (): FieldComponent => ({
      is: Component.Render,
    }),
    transactionCountCurrentWeek: (): FieldComponent => ({
      is: Component.Render,
    }),
    transactionCountLastWeek: (): FieldComponent => ({
      is: Component.Render,
    }),
    date: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: $.filter.datetime(schema.model.date),
      },
    }),
  }
}
