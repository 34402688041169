


















































































































import {Component, Prop, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterDappDailyCache from '@/components/filters/FilterDappDailyCache.vue'
import {DappDailyCache} from '@/model/resource/DappDailyCache'
import {DappDailyCacheCollection} from '@/model/collection/DappDailyCacheCollection'
import {ListDappDailyCacheSchema} from '@/schema/resource/DappDailyCache/ListDappDailyCacheSchema'
import {ExportDappDailyCacheSchema} from '@/schema/resource/DappDailyCache/ExportDappDailyCacheSchema'

@Component({
  components: {FilterToggle, FilterDappDailyCache},
})
export default class ListDappDailyCacheView extends Mixins(MixinRouteMatch) {
  schema = new ListDappDailyCacheSchema()
  collection = new DappDailyCacheCollection()
  filterOpen = false

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: DappDailyCache) {
    this.$nav.pushByName('editDappDailyCache', item.idDappFk)
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new DappDailyCacheCollection().clearFilters().addFilter(params)

    await coll.listExportDappDailyCache()
    this.$xlsx.downloadFromSchema(coll.items, new ExportDappDailyCacheSchema())
  }
}
