/**
 * Export Schema of DappDailyCache
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {DappDailyCache} from '@/model/resource/DappDailyCache'

/* TODO: review generated schema */
export class ExportDappDailyCacheSchema extends DefaultSchema {
  readonly name = 'ExportDappDailyCache'

  readonly fieldSet: FieldSet<DappDailyCache> = {
    score: schema => schema.model.score,
    transactionCountToday: schema => schema.model.transactionCountToday,
    transactionCountCurrentWeek: schema =>
      schema.model.transactionCountCurrentWeek,
    transactionCountLastWeek: schema => schema.model.transactionCountLastWeek,
    date: schema => $.filter.datetime(schema.model.date),
  }
}
